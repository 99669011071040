import React, { Component } from "react";
import AboutPicture from "../img/GoogleUs.jpg";
import LanguagePicture from "../img/language.png";
import LanguagePic from "../img/languages.png";
import LanguagesPic from "../img/languag.jpg";

class about extends Component {
  render() {
    return (
      <section className="bg-light page-section" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <img
                className="img mx-auto rounded-circle about-cover"
                src={AboutPicture}
                alt=""
              />
              <h2 className="section-heading text-uppercase">
                Who Is Sinothando
              </h2>
              <h3 className="section-subheading text-muted">
                I am a Cape Town based junior web developer with a passion for
                both front and back end development. I'm currently enrolled in a
                coding course at{" "}
                <span>
                  <a href="https://www.lifechoices.co.za/academy">
                    LifeChoices Academy
                  </a>
                  . I aspire toward a path that will allow me to channel my
                  creativity and innovation.
                </span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="language-about">
              <img
                className="mx-auto rounded-circle"
                src={LanguagesPic}
                alt=""
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="language-about">
              <img
                className="mx-auto rounded-circle"
                src={LanguagePicture}
                alt=""
              /></div>
          </div>
          <div className="col-sm-4">
            <div className="language-about">
              <img
                className="mx-auto rounded-circle"
                src={LanguagePic}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <p className="large text-muted">
              “I want to put a ding in the universe.”
              ― Steve Jobs
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default about;
