import React, { Component } from "react";

class intro extends Component {
  render() {
    return (
      <div>
        <header className="masthead" id="page-top">
          <div className="container">
            <div className="intro-text">
              <div className="intro-heading text-uppercase">
                Sinothando Bacela
              </div>
              <div className="intro-lead-in">Junior Web Developer</div>
              <a
                className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                href="#skills"
              ><i className="fa fa-download" aria-hidden="true"></i> Download Resume
              </a>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default intro;
