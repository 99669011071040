import React from "react";
import CoverPicture from "../img/coder.gif";

export default ({ portfolioLinks }) => {
  return (
    <section className="bg-light page-section" id="portfolio">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Portfolio</h2>
            <h3 className="section-subheading text-muted">
              Here is some of the work I've done. More to come..{" "}
              <i class="fa fa-thumbs-up" aria-hidden="true"></i>
            </h3>
          </div>{" "}
        </div>
        <div className="row">
          {portfolioLinks &&
            portfolioLinks.map(({ title, caption, link, repo }, index) => (
              <div className="col-md-4 col-sm-6 portfolio-item">
                <a
                  className="portfolio-link"
                  data-toggle="modal"
                  href={link}
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fa fa-search-plus fa-3x"></i>
                    </div>
                  </div>
                  <img
                    className="img-fluid"
                    src={CoverPicture}
                    alt="portfolio_img"
                  />
                </a>
                <div className="portfolio-caption">
                  <h4><a href={repo}>{title}</a></h4>
                  <p className="text-muted">(source code)</p>
                  <p className="text-muted"><i className="fa fa-tag" aria-hidden="true"></i> {caption}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
