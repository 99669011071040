import React from 'react';
import Navbar from "./components/navbar/navbar";
import Intro from "./components/intro/intro";
import Skills from "./components/skills/skills";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";
import Portfolio from "./components/portfolio/portfolio";
// import Testimonials from "./components/testimonials/testimonials";
import './assets/css/bootstrap.min.css'
import './App.css';

function App() {
  const portfolioLinks = [
    {
      title: "GitHub Finder",
      caption: "React, JS, HTML",
      link:"https://gihubfinder980330.netlify.app/",
      repo:"https://github.com/SheMortal/GithubFinder"
    },
    {
      title: "Contact Keeper",
      caption: "React, JS, MongoDB",
      link: "",
      repo:"https://github.com/SheMortal/Contact-Keeper"
    },
    {
      title: "It-Logger",
      caption: "React, JS, HTML",
      link: "",
      repo:"https://github.com/SheMortal/IT-Logger"
    },
    {
      title: "Lense Life",
      caption: "Photography",
      link:"https://www.instagram.com/shemortal/",
      repo:""
    }
  ];
  return (
    <div className="App">
    <Navbar></Navbar>
    <Intro></Intro>
    <About></About>
    <Skills></Skills>
    <Portfolio portfolioLinks={portfolioLinks}></Portfolio>
    {/* <Testimonials></Testimonials> */}
    <Contact></Contact>
    <Footer></Footer>
    </div>
  );
}

export default App;
