import React, { Component } from "react";
import Mongo from "../img/mongodb.png";
import Github from "../img/github.png";
import React_Image from "../img/react.png";
import Languages from "../img/jslanguages.png";
import JS from "../img/slanguage.jpg";
import Python_img from "../img/python.png";

class skills extends Component {
  render() {
    return (
      <div>
        <section className="page-section" id="skills">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">Skills</h2>
                <h1 className="section-subheading text-muted">
                  <i className="fa fa-code" aria-hidden="true"></i>
                </h1>
              </div>
              <div className="container-skills">
                <img
                  className="skills-img"
                  src={Python_img}
                  alt="mongo-img"
                ></img>
                <img className="skills-img" src={Mongo} alt="mongo-img"></img>
                <img className="skills-img" src={Github} alt="github-img"></img>
                <img
                  className="skills-img"
                  src={React_Image}
                  alt="react-img"
                ></img>
                <img
                  className="skills-img"
                  src={Languages}
                  alt="languages-img"
                ></img>
                <img className="skills-img" src={JS} alt="js-img"></img>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="progress-wrap">
                    <h3>HTML5</h3>
                    <div className="progress">
                      <div
                        className="progress-bar color-3"
                        role="progressbar"
                        aria-valuenow={85}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "85%" }}
                      >
                        <span>85%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="progress-wrap">
                    <h3>CSS3</h3>
                    <div className="progress">
                      <div
                        className="progress-bar color-4"
                        role="progressbar"
                        aria-valuenow={45}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "45%" }}
                      >
                        <span>45%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="progress-wrap">
                    <h3>Python</h3>
                    <div className="progress">
                      <div
                        className="progress-bar color-1"
                        role="progressbar"
                        aria-valuenow={90}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "90%" }}
                      >
                        <span>90%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="progress-wrap">
                    <h3>JQuery</h3>
                    <div className="progress">
                      <div
                        className="progress-bar color-2"
                        role="progressbar"
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "40%" }}
                      >
                        <span>40%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="progress-wrap">
                    <h3>SQL Database</h3>
                    <div className="progress">
                      <div
                        className="progress-bar color-5"
                        role="progressbar"
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "70%" }}
                      >
                        <span>70%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="progress-wrap">
                    <h3>JavaScript</h3>
                    <div className="progress">
                      <div
                        className="progress-bar color-6"
                        role="progressbar"
                        aria-valuenow={50}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "50%" }}
                      >
                        <span>50%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">Skills</h2>
                <h1 className="section-subheading text-muted">
                  <i class="fa fa-code" aria-hidden="true"></i>
                </h1>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-4">
                <span className="fa-stack fa-4x">
                  <i className="fa fa-circle fa-stack-2x text-primary"></i>
                  <i className="fa fa-shopping-cart fa-stack-1x fa-inverse"></i>
                </span>
                <h4 className="service-heading">E-Commerce</h4>
                <p className="text-muted">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Minima maxime quam architecto quo inventore harum ex magni,
                  dicta impedit.
                </p>
              </div>
              <div className="col-md-4">
                <span className="fa-stack fa-4x">
                  <i className="fa fa-circle fa-stack-2x text-primary"></i>
                  <i className="fa fa-laptop fa-stack-1x fa-inverse"></i>
                </span>
                <h4 className="service-heading">Responsive Design</h4>
                <p className="text-muted">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Minima maxime quam architecto quo inventore harum ex magni,
                  dicta impedit.
                </p>
              </div>
              <div className="col-md-4">
                <span className="fa-stack fa-4x">
                  <i className="fa fa-circle fa-stack-2x text-primary"></i>
                  <i className="fa fa-database fa-stack-1x fa-inverse"></i>
                </span>
                <h4 className="service-heading">SQL Databases</h4>
                <p className="text-muted">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Minima maxime quam architecto quo inventore harum ex magni,
                  dicta impedit.
                </p>
              </div>
              <div className="progress-wrap">
                <h3>HTML</h3>
                <div className="progress">
                  <div
                    className="progess-bar color-3"
                    role="progressbar"
                    aria-valuenow={85}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "85%" }}
                  >
                    <span>85%</span>
                  </div>
                </div>
              </div>
              <div className="progress-wrap">
                <h3>HTML</h3>
                <div className="progress">
                  <div
                    className="progess-bar color-3"
                    role="progressbar"
                    aria-valuenow={85}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "85%" }}
                  >
                    <span>85%</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      </div>
    );
  }
}

export default skills;
