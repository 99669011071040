import React, { Component } from "react";

class footer extends Component {
  render() {
    return (
      <footer className="footer_">
        <div className="row text-align">
          <div className="twelve columns">
            <ul className="social-links">
              <li>
                <a href="https://www.facebook.com/sinothando.bacela">
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/sinothando_bace">
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://za.linkedin.com/in/sinothando-bacela-850aba13a">
                  <i className="fa fa-linkedin" />
                </a>
              </li>
              <li>
                <a href="https://github.com/SheMortal">
                  <i className="fa fa-github" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/sinothando_bacela/?hl=en">
                  <i className="fa fa-instagram" />
                </a>
              </li>
            </ul>
            <ul className="copyright">
              <li>
                Made with <i class="fa fa-heart" aria-hidden="true"></i> by
                Sinothando Bacela
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default footer;
